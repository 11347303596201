import * as React from 'react';
import { css } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import 'typeface-merienda-one';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Typer from '../components/typer';
import Hr from '../components/hr';
import PostBlock from '../components/post-block';
import padding from '../util/padding';

const typerProps = {
  base: 'Love is ',
  endings: [
    'patient.',
    'kind.',
    'not envious.',
    'not boastful.',
    'not arrogant.',
    'not rude.',
    'not silent.',
  ],
};

const IndexPage = () => {
  const data = useStaticQuery<{
    site: {
      siteMetadata: {
        description: string;
      };
    };
    image: { childImageSharp: { fluid: { src: string; srcSet: string } } };
    allMdx: {
      edges: Array<{
        node: {
          id: string;
          timeToRead: string;
          frontmatter: {
            slug: string;
            title: string;
            date: string;
            tags: Array<string>;
            hero: {
              darken?: string;
              author?: string;
              unsplashUser?: string;
              file: {
                childImageSharp: {
                  fluid: {
                    src: string;
                    srcSet: string;
                  };
                };
              };
            };
          };
          body: string;
          excerpt: string;
        };
      }>;
    };
  }>(graphql`
    query {
      site {
        siteMetadata {
          description
        }
      }
      image: file(
        relativePath: { eq: "patrick-fore-b_SHPU5M3nk-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75) {
            src
            srcSet
          }
        }
      }
      allMdx(
        skip: 0
        limit: 10
        sort: { order: DESC, fields: frontmatter___date }
        filter: { fields: { type: { eq: "posts" } } }
      ) {
        edges {
          node {
            id
            excerpt
            timeToRead
            frontmatter {
              slug
              title
              date(formatString: "MMMM DD, YYYY")
              tags
              hero {
                file {
                  childImageSharp {
                    fluid(maxWidth: 720, maxHeight: 400, fit: COVER) {
                      src
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts = data.allMdx.edges;

  return (
    <Layout withHero>
      <SEO title="Home" description={data.site.siteMetadata.description} />
      <Hero
        {...data.image.childImageSharp.fluid}
        unsplashUser="patrickian4"
        author="Patrick Fore"
      >
        <Typer
          {...typerProps}
          css={css`
            z-index: 2;
            color: #fff;
            font-size: 50px;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
            text-align: center;
            @media print {
              display: none;
            }
          `}
        />
      </Hero>
      <h2
        css={css`
          text-align: center;
          margin-top: 5em;
          ${padding}
        `}
      >
        Blog
      </h2>
      <div
        css={css`
          ${padding}
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        {posts.map(({ node: post }) => {
          return (
            <PostBlock
              key={post.id}
              slug={post.frontmatter.slug}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              tags={post.frontmatter.tags}
              excerpt={post.excerpt}
              image={post.frontmatter.hero.file.childImageSharp.fluid}
            />
          );
        })}
      </div>
      <Hr />
      <h2
        css={css`
          text-align: center;
          font-family: 'Merienda One';
          line-height: 1.33em;
          font-size: 26px;
          ${padding}
        `}
      >
        Love is patient and kind; love does not envy or boast; it is not
        arrogant or&nbsp;rude;
        <br />
        and it is certainly not silent.
      </h2>
      <Hr />
    </Layout>
  );
};

export default IndexPage;
