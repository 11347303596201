import * as React from 'react';
import { css } from '@emotion/core';

export default () => (
  <hr
    css={css`
      margin: 6em 0;
      background: linear-gradient(
        to right,
        transparent 25%,
        #818078,
        transparent 75%
      );
    `}
  />
);
