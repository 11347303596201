import * as React from 'react';
import { keyframes, css } from '@emotion/core';

export type ClassNameProps = Pick<React.HTMLProps<HTMLDivElement>, 'className'>;

export type Props = {
  Comp?: string | React.ComponentType<ClassNameProps>;
  base: string;
  endings: Array<string>;
  keyTime?: number;
  delay?: number;
} & ClassNameProps;

const blink = keyframes`
  from, to {
    background: transparent;
    box-shadow: none;
  }

  50% {
    background: #FFF;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  }
`;

const Typer = ({
  Comp = 'h1',
  base,
  endings,
  keyTime = 200,
  delay = 1500,
  className,
}: Props) => {
  const [endingIdx, setEndingIdx] = React.useState(0);
  const [typingIdx, setTypingIdx] = React.useState(0);
  const [forward, setForward] = React.useState(true);

  const ending = endings[endingIdx];
  const totalLen = base.length + ending.length;
  const isTyping = typingIdx < totalLen && typingIdx !== base.length;

  React.useEffect(() => {
    const timer = setTimeout(
      () => {
        const add = forward ? 1 : -1;
        setTypingIdx(idx => idx + add);

        if (forward) {
          setForward(typingIdx + add < totalLen);
        } else {
          const change = typingIdx + add === base.length;
          setForward(change);
          if (change) {
            setEndingIdx(idx => (idx + 1) % endings.length);
          }
        }
      },
      typingIdx === 0 || !isTyping ? delay : keyTime,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [keyTime, endings, delay, typingIdx, base, forward, totalLen, isTyping]);

  const intoEnding = typingIdx - base.length;

  return (
    <Comp className={className}>
      {base.substr(0, typingIdx)}
      {ending.substr(0, intoEnding)}
      <div
        role="presentation"
        css={css`
          animation: ${!isTyping || typingIdx === 0
            ? css`
                ${blink} 1s step-end infinite
              `
            : null};
          background: #fff;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
          width: 4px;
          height: 50px;
          display: inline-block;
          transform: translate3d(5px, 5px, 0);
        `}
      />
    </Comp>
  );
};

export default Typer;
