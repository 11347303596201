import * as React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import Tags from './tags';

export type Props = {
  slug: string;
  title: string;
  date: string;
  tags: Array<string>;
  excerpt: string;
  image: {
    src: string;
    srcSet: string;
  };
};

const PostBlock = ({ slug, title, date, tags, excerpt, image }: Props) => {
  const to = `/blog/${slug}`;

  return (
    <article
      css={css`
        max-width: 720px;
        padding: 1em;
        margin-bottom: 5em;
      `}
    >
      <Link to={to}>
        <img {...image} alt={title} />
      </Link>
      <h2
        css={css`
          margin: 0 0 15px 0;
        `}
      >
        <Link
          to={to}
          css={css`
            color: inherit;
            text-decoration: none;
          `}
        >
          {title}
        </Link>
      </h2>
      <h3
        css={css`
          font-size: 16px;
          color: #555;
          margin-bottom: 15px;
        `}
      >
        <span
          css={css`
            margin-right: 8px;
          `}
        >
          {date}
        </span>
        <Tags tags={tags} />
      </h3>
      <div>
        <Link
          to={to}
          css={css`
            color: inherit;
            text-decoration: none;
          `}
        >
          {excerpt}
        </Link>
      </div>
    </article>
  );
};

export default PostBlock;
